import React, { useEffect } from 'react'
import surono from "../assets/surono.png"
import { HeadingsLobster } from './htmlDOM/headingLobster'
import SEO from './SEO'
export const HF = () => {

    return (
        <div  >
           
            <div className='px-30 '>
                <section className='flex lg:flex-row mf:flex-col-reverse items-center justify-center relative'>
                    <div class=" py-8 px-4 mx-auto max-w-screen-xl lg:py-16 grid lg:grid-cols-2 gap-8 lg:gap-16">
                        <div class="flex flex-col justify-center">
                        <SEO title="Travel Umroh jogja januari 2024 Murah " description="yuk berangkat ,dengan order umroh murah paket komplit januari 2024 harga 30 juta  " name="MIW Jogja" type="article" key="Umroh Ramah Kantong | Umroh Murah| Umroh Jogja Murah|Umroh Paket Komplit|Umroh tour bersertifikat"/>
                            <h1 class="slide-right mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl ">Umroh Spesial Promo Januari  <span className='flex-end  bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent'>Madinah Iman Wisata</span> 2024</h1>
                            <p class="slide-right-1 mb-8 text-lg font-normal text-gray-100 lg:text-xl ">Saatnya mewujudkan impian suci kita untuk umroh bersama keluarga tercinta atau umroh bersama teman-teman kantor tercinta. Konsultasikan rencana ibadah umroh dan haji kita bersama Madinah Iman Wisata(MIW) sekarang. Bersiaplah untuk memulai perjalanan menuju reuni abadi di surga Insya Allah.</p>
                            <div class="slide-right-2 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                                <a href="https://api.whatsapp.com/send?phone=6281904235462&text=Assalamuallaikum%20Umroh%20Haji%20Sekeluarga%20%2Cmau%20tanya%20kuota%20umroh%20januari%20tahun%202024%20masih%20ada%20tidak%20ya%20saya%20berminat" class="slide-top-button-konsultasi inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-gradient-to-r from-red-500 via-amber-600 to-orange-600 hover:bg-green-700 focus:ring-4 focus:ring-blue-300 dark:focus:ring-teal-900">
                                    Konsultasi via WA
                                    <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
                                    </svg>
                                </a>

                            </div>
                        </div>
                    </div>
                    <div>
                        <HeadingsLobster text="H. Surono Achmad, S.Pd" size="text-[35px]" costum_css="mf:block lg:hidden"/>
                    </div>
                    <div className='lg:absolute lg:pl-96 lg:top-[-70px] '>
                        <img src={surono} className='slide-top-img lg:h-[560px] mf:h-[300px]' alt="ustadz achmad surono miw agen jogja" />
                        <HeadingsLobster text="H. Surono Achmad, S.Pd" size="text-[50px]" costum_css="mf:hidden lg:block"/>
                    </div>
                </section>
            </div>
        </div>
    )
}
