import React,{useEffect} from 'react'
import RTW from "../assets/compressing-df.jpg"
import SEO from './SEO';
export const Brocure = () => {
    useEffect(() => {
        const reveal = () => {
          const reveals = document.querySelectorAll(".reveal");
    
          for (let i = 0; i < reveals.length; i++) {
            const windowHeight = window.innerHeight;
            const elementTop = reveals[i].getBoundingClientRect().top;
            const elementVisible = 150;
    
            if (elementTop < windowHeight - elementVisible) {
              reveals[i].classList.add("active");
            } else {
              reveals[i].classList.remove("active");
            }
          }
        };
    
        window.addEventListener("scroll", reveal);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener("scroll", reveal);
        };
      }, []); // Empty dependency array to ensure the effect runs once after the initial render
    
    return (
        <div className='flex lg:flex-row mf:flex-col lg:w-full mx-auto gap-4 items-center justify-center py-10  revent '>
          <SEO description="Brosur Umroh Murah Jogja 30 juta paket komplit" name="Madinah Iman Wisata Jogja|UmrohHajiSekeluarga" content="umroh 30 juta complete januari 2024 bersama madinah iman wisata" type="article"  />
            <img src={RTW} className='w-[400px] h-[60%] ' alt="umroh 30 juta complete januari 2024 bersama madinah iman wisata  " />
            <img src={RTW} className='w-[400px] h-[60%] mf:hidden lg:block' alt="umroh 30 juta complete januari 2024 bersama madinah iman wisata  " />
            <img src={RTW} className='w-[400px] h-[60%] mf:hidden lg:hidden 2xl:block' alt="umroh 30 juta complete januari 2024 bersama madinah iman wisata " />
        </div>
    )
}
