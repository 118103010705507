import React from 'react'

export const Pricing = () => {
    return (
        <div className='items-center flex flex-col bg-gradient-to-br from-black via-black to-red-950'>
            <h1 className='text-white text-4xl  font-extrabold  mx-auto py-10 text-center  self-center'>  Harga Rp 30 JT <br /><span className='text-[50px] bg-gradient-to-tr from-orange-400  shadow-orange-500 via-amber-600 to-orange-600 bg-clip-text text-transparent'>Tidak Ada Biaya Tambahan</span> <br /> (Solo to Solo)</h1>
            <div className='flex mf:flex-col lg:flex-row lg:px-44 mf:px-5 py-10 w-full lg:gap-2 mf:gap-10'>
                <div className='flex-col flex w-full h-96  lg:gap-2 mf:gap-5 mf:pb-2'>
                    <div className=' bg-black  border border-orange-600 shadow-md shadow-orange-500  lg:h-6/6  w-full rounded-sm'>
                        <h1 className='text-[25px] font-extrabold text-center bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent'>Pesawat Internasional PP</h1>
                        <ul class="lg:max-w-md space-y-1 text-white list-inside  px-5 py-3">
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Penerbangan Langsung
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Solo to Jeddah
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Jeddah to Solo
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Pesawat Lion Air
                            </li>
                            <li class="flex flex-col items-left gap-2 lg:text-sm mf:text-[11px]">
                                <i>Tidak Termasuk Transport Dari Jogjakarta ke Bandara</i>
                            </li>
                            <li class="flex flex-col items-left gap-2 lg:text-sm mf:text-[11px]">
                                <i>Tidak Termasuk Biaya Tambahan Over Bagasi</i>
                            </li>
                        </ul>
                    </div>
                    <div className='bg-black  border border-orange-600 shadow-md shadow-orange-500      w-full rounded-sm xl:h-[19vh] mf:h-5/6 lg:pb-10'>
                        <h1 className='text-[21px] font-extrabold text-center bg-gradient-to-r  from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent py-1 leading-5'>Gratis Passport dan Visa </h1>
                        <ul class="lg:max-w-md space-y-1 text-white list-inside dark:text-white px-5 lg:py-0 mf:py-5">
                            <li class="flex items-center text-[14px]">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Pembuatan Passport dan Visa Baru
                            </li>
                            <li class="flex items-center text-[14px]">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Perpanjang Passport dan Visa
                            </li>
                            <li class="flex items-center gap-2 text-[14px]">
                                <i>
                                    Tidak Termasuk Passport Hilang
                                </i>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex-col flex w-full   lg:gap-2 mf:gap-5 mf:pt-2 lg:pt-0'>
                    <div className='bg-black  border border-orange-600 shadow-md shadow-orange-500     lg:h-2/6 mf:h-5/6 w-full rounded-sm  w-full '>
                        <h1 className='text-[23px] font-extrabold text-center bg-gradient-to-r bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent pt-3'>Perlengkapan Komplit </h1>
                        <ul class="lg:max-w-md space-y-1 text-white list-inside dark:text-white px-5 mf:py-5 lg:pt-5">
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Handling Umroh
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Perlengkapan Umroh
                            </li>
                        </ul>
                    </div>
                    <div className='bg-black  border border-orange-600 shadow-md shadow-orange-500     h-4/6 w-full rounded-sm'>
                        <h1 className='text-[26px] font-extrabold text-center bg-gradient-to-r bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent'>Transportasi di Saudi</h1>
                        <ul class="lg:max-w-md space-y-1 text-white list-inside text-white px-5 py-5">
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Bus Full AC
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                City Tour Mekkah - Madinah
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Tour Leader Bersertifikat
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Muthowif Berpengalaman
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex-col flex w-full  lg:gap-2 mf:gap-5'>
                    <div className='bg-black  border border-orange-600 shadow-md shadow-orange-500     h-4/6 w-full rounded-sm'>
                        <h1 className='text-[26px] font-extrabold text-center bg-gradient-to-r bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent py-3'>Hotel Sesuai Paket</h1>
                        <ul class="lg:max-w-md space-y-1 text-white list-inside text-white px-5 py-5">
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Penerbangan Langsung
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Hotel Madinah EMAAR TAIBA Bintang 3/SETARAF
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Hotel Mekkah MATHER EL AIMAN Bintang *3/SETARAF
                            </li>
                        </ul>
                    </div>
                    <div className='bg-black  border border-orange-600 shadow-md shadow-orange-500     lg:h-2/6 mf:h-5/6 w-full rounded-sm'>
                        <h1 className='text-[26px] font-extrabold text-center bg-gradient-to-r bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent'>Makan 3x Sehari</h1>
                        <ul class="lg:max-w-md space-y-1 text-white list-inside text-white px-5 py-3">
                            <li class="flex items-center text-sm">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Makanan Pagi Bergizi
                            </li>
                            <li class="flex items-center text-sm">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Makan Siang Bergizi
                            </li>
                            <li class="flex items-center text-sm">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Makan Malam Gergizi
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='flex-col flex w-full lg:gap-2 mf:gap-5'>
                    <div className='bg-black  border border-orange-600 shadow-md shadow-orange-500     lg:h-2/6 mf:h-5/6 w-full items-center'>
                        <h1 className='text-[22px] font-extrabold text-center bg-gradient-to-r bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent'>Manasik dan Asuransi</h1>
                        <ul class="lg:max-w-md space-y-1 text-white list-inside text-white px-5 py-3">
                            <li class="flex items-center text-sm">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Pelatihan Manasik Gratis
                            </li>
                            <li class="flex items-center text-sm">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Pembimbing Bersertifikat
                            </li>
                            <li class="flex items-center text-sm">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Asuransi Kesehatan dan Barang
                            </li>
                        </ul>
                    </div>
                    <div className='bg-black  border border-orange-600 shadow-md shadow-orange-500     h-4/6 w-full rounded-sm'>
                        <h1 className='text-[24px] font-extrabold text-center bg-gradient-to-r bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent py-3'>Sertifikat <br/> Dan Oleh-oleh</h1>
                        <ul class="lg:max-w-md space-y-1 text-white list-inside text-white px-5 py-5">
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Sertifikat Umroh
                            </li>
                            <li class="flex items-center">
                                <svg class="w-3.5 h-3.5 mr-2 text-green-500 dark:text-green-400 flex-shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                Air Zam-Zam 5 Liter
                            </li>
                            <li class="flex items-center">
                                <i>
                                   Jika Diizinkan Maka Air Zam-Zam  Dapat Dibawa ke Indonesia 
                                </i>

                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    )
}
