import React from 'react'
import SEO from './SEO'
export const MIWProud = () => {
    return (
        <div
            className="flex mf:flex-col   items-center justify-center lg:w-full mx-auto  lg:px-10 pb-10">
            <h1
                className=" bg-gradient-to-tr from-amber-600 via-amber-600 to-orange-600 bg-clip-text text-transparent text-3xl font-bold py-10 self-center mf:text-center ">Mengapa Travel Umroh Madinah Iman Wisata?</h1>
            <div className="flex flex-col w-5/6 gap-8 mf:items-center">
                <div className="flex lg:flex-row mf:flex-col gap-4 ">
                    <div
                        className="max-w-sm relative p-6 bg-black  border border-orange-600  shadow-amber-600 rounded-lg shadow-xl lg:miw__background-clip">
                         <SEO title="Travel Umroh jogja januari 2024 Murah " description="yuk berangkat ,dengan order umroh murah paket komplit januari 2024 harga 30 juta  " name="MIW Jogja" type="article" key="Umroh Ramah Kantong | Umroh Murah| Umroh Jogja Murah|Umroh Paket Komplit|Umroh tour bersertifikat"/>
                        <svg
                            className="fill-amber-400 w-10 h-10 lg:absolute lg:right-10  lg:top-[20px]"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"><path
                                d="M381 114.9L186.1 41.8c-16.7-6.2-35.2-5.3-51.1 2.7L89.1 67.4C78 73 77.2 88.5 87.6 95.2l146.9 94.5L136 240 77.8 214.1c-8.7-3.9-18.8-3.7-27.3 .6L18.3 230.8c-9.3 4.7-11.8 16.8-5 24.7l73.1 85.3c6.1 7.1 15 11.2 24.3 11.2H248.4c5 0 9.9-1.2 14.3-3.4L535.6 212.2c46.5-23.3 82.5-63.3 100.8-112C645.9 75 627.2 48 600.2 48span42.8c-20.2 0-40.2 4.8-58.2 14L381 114.9zM0 480c0 17.7 14.3 32 32 32H608c17.7 0 32-14.3 32-32s-14.3-32-32-32H32c-17.7 0-32 14.3-32 32z" />
                        </svg>
                        <a href="#">
                            <span
                                className="mb-2 text-2xl font-semibold tracking-tight bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent ">Direct
                                Flight</span>
                        </a>
                        <p
                            className="mb-3 font-normal text-gray-200 "> Kami menggunakan pesawat Lion direct Jeddah. Sehingga kami tidak mampir dulu di Malaysia, Singapur atau tempat lain. Sehingga jamaah tidak terlalu lelah dan terlalu lama dalam perjalanan. Ini salah satu keunggulan travel umroh yg  terakreditasi A, seperti MIW (Madinah Iman Wisata) ini. </p>

                    </div>
                    <div
                        className="max-w-sm relative p-6 bg-black  border border-orange-600  shadow-amber-600 rounded-lg shadow-xl rounded-lg shadow lg:miw__background-clip">
                         <SEO title="Travel Umroh jogja januari 2024 Murah " description="yuk berangkat ,dengan order umroh murah paket komplit januari 2024 harga 30 juta  " name="MIW Jogja" type="article" key="Umroh Ramah Kantong | Umroh Murah| Umroh Jogja Murah|Umroh Paket Komplit|Umroh tour bersertifikat"/>
                        <svg
                            className="fill-amber-400 w-10 h-10 lg:absolute lg:right-10  lg:top-[20px] "
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"><path
                                d="M211 7.3C205 1 196-1.4 187.6 .8s-14.9 8.9-17.1 17.3L154.7 80.6l-62-17.5c-8.4-2.4-17.4 0-23.5 6.1s-8.5 15.1-6.1 23.5l17.5 62L18.1 170.6c-8.4 2.1-15 8.7-17.3 17.1S1 205 7.3 211l46.2 45L7.3 301C1 307-1.4 316 .8 324.4s8.9 14.9 17.3 17.1l62.5 15.8-17.5 62c-2.4 8.4 0 17.4 6.1 23.5s15.1 8.5 23.5 6.1l62-17.5 15.8 62.5c2.1 8.4 8.7 15 17.1 17.3s17.3-.2 23.4-6.4l45-46.2 45 46.2c6.1 6.2 15 8.7 23.4 6.4s14.9-8.9 17.1-17.3l15.8-62.5 62 17.5c8.4 2.4 17.4 0 23.5-6.1s8.5-15.1 6.1-23.5l-17.5-62 62.5-15.8c8.4-2.1 15-8.7 17.3-17.1s-.2-17.3-6.4-23.4l-46.2-45 46.2-45c6.2-6.1 8.7-15 6.4-23.4s-8.9-14.9-17.3-17.1l-62.5-15.8 17.5-62c2.4-8.4 0-17.4-6.1-23.5s-15.1-8.5-23.5-6.1l-62 17.5L341.4 18.1c-2.1-8.4-8.7-15-17.1-17.3S307 1 301 7.3L256 53.5 211 7.3z" /></svg>
                        <a href="#">
                            <span
                                className="mb-2 text-2xl font-semibold tracking-tight bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent ">Resmi
                                dan Terpercaya</span>
                        </a>
                        <p
                            className="mb-3 font-normal text-gray-200 ">Dengan
                            maraknya penipuan oleh beberapa travel
                            penyelenggara umroh dan haji. Maka pemerintah melalui kementrian agama
                            mewajibkan akreditasi. Alhamdulillah MIW
                            memiliki Akreditasi “A”.</p>

                    </div>
                    <div
                        className="max-w-sm relative p-6 bg-black  border border-orange-600  shadow-amber-600 rounded-lg shadow-xl lg:miw__background-clip">
                         <SEO title="Travel Umroh jogja januari 2024 Murah " description="yuk berangkat ,dengan order umroh murah paket komplit januari 2024 harga 30 juta  " name="MIW Jogja" type="article" key="Umroh Ramah Kantong | Umroh Murah| Umroh Jogja Murah|Umroh Paket Komplit|Umroh tour bersertifikat"/>
                        <svg
                            className="fill-amber-400 w-10 h-10 lg:absolute lg:right-10  lg:top-[20px]"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"><path
                                d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 352h8.2c32.3-39.1 81.1-64 135.8-64c5.4 0 10.7 .2 16 .7V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM320 352H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H360.2C335.1 449.6 320 410.5 320 368c0-5.4 .2-10.7 .7-16l-.7 0zm320 16a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM496 288c8.8 0 16 7.2 16 16v48h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H496c-8.8 0-16-7.2-16-16V304c0-8.8 7.2-16 16-16z" />
                        </svg>
                        <a href="#">
                            <span
                                className="mb-2 text-2xl font-semibold tracking-tight bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent ">Kepastian
                                Jadwal</span>
                        </a>
                        <p
                            className="mb-3 font-normal text-gray-200 ">MIW memiliki jaringan usaha yang luas di Indonesia dan Arab Saudi untuk sistem booking seat hotel dan pesawat. Sehingga jamaah insya Allah bisa berangkat ibadah haji ataupun umroh</p>

                    </div>

                </div>
                <div className="flex lg:flex-row mf:flex-col gap-4">
                    <div
                        className="max-w-sm relative p-6 bg-black  border border-orange-600  shadow-amber-600 rounded-lg shadow-xl rounded-lg shadow lg:miw__background-clip">
                         <SEO title="Travel Umroh jogja januari 2024 Murah " description="yuk berangkat ,dengan order umroh murah paket komplit januari 2024 harga 30 juta  " name="MIW Jogja" type="article" key="Umroh Ramah Kantong | Umroh Murah| Umroh Jogja Murah|Umroh Paket Komplit|Umroh tour bersertifikat"/>
                        <svg
                            className="fill-amber-400 w-10 h-10 lg:absolute lg:right-10  lg:top-[20px]"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"><path
                                d="M480 48c0-26.5-21.5-48-48-48H336c-26.5 0-48 21.5-48 48V96H224V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V96H112V24c0-13.3-10.7-24-24-24S64 10.7 64 24V96H48C21.5 96 0 117.5 0 144v96V464c0 26.5 21.5 48 48 48H304h32 96span92c26.5 0 48-21.5 48-48V240c0-26.5-21.5-48-48-48H480V48zm96 320v32c0 8.8-7.2 16-16 16span28c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM240 416H208c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zM128 400c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zM560 256c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16span28c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32zM256 176v32c0 8.8-7.2 16-16 16H208c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM112 160c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32zM256 304c0 8.8-7.2 16-16 16H208c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32zM112 320H80c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16zm304-48v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V272c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16zM400 64c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V80c0-8.8 7.2-16 16-16h32zm16 112v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16z" />
                        </svg>
                        <a href="#">
                            <span
                                className="mb-2 text-2xl font-semibold tracking-tight bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent ">Gratis
                                City Tour</span>
                        </a>
                        <p
                            className="mb-3 font-normal text-gray-200 ">Selain dibimbing agar khidmat dalam beribadah, jamaah umroh diberikan fasilitas city tour. Diantaranya mengunjungi tempat-tempat bersejarah dan lokasi belanja yang nyaman di sekitar Makkah, Madinah, serta Jeddah.</p>

                    </div>
                    <div
                        className="max-w-sm relative p-6 bg-black  border border-orange-600  shadow-amber-600 rounded-lg shadow-xl lg:miw__background-clip ">
                         <SEO title="Travel Umroh jogja januari 2024 Murah " description="yuk berangkat ,dengan order umroh murah paket komplit januari 2024 harga 30 juta  " name="MIW Jogja" type="article" key="Umroh Ramah Kantong | Umroh Murah| Umroh Jogja Murah|Umroh Paket Komplit|Umroh tour bersertifikat"/>
                        <svg xmlns="http://www.w3.org/2000/svg"
                            className="fill-amber-400 w-10 h-10 lg:absolute lg:right-10  lg:top-[20px]"
                            viewBox="0 0 512 512"><path
                                d="M0 32C0 14.3 14.3 0 32 0H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H304V464c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64C14.3 64 0 49.7 0 32zm96 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zM240 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H240zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zM112 192c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H112zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H368zM328 384c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8H328z" /></svg>

                        <a href="#">
                            <span
                                className="mb-2 text-2xl font-semibold tracking-tight bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent ">Hotel
                                Dekat Masjid</span>
                        </a>
                        <p
                            className="mb-3 font-normal text-gray-200 ">Untuk kenyamanan dan ke-kekhus'uan ibadah, MIW memilih hotel dekat Masjid Haram dan Masjid Nabawi sehingga jamaah dapat beribadah secara maksimal saat di tanah suci.


                        </p>

                    </div>
                    <div
                        className="max-w-sm relative p-6 bg-black  border border-orange-600 shadow-2xl shadow-amber-600 rounded-lg shadow lg:miw__background-clip">
                         <SEO title="Travel Umroh jogja januari 2024 Murah " description="yuk berangkat ,dengan order umroh murah paket komplit januari 2024 harga 30 juta  " name="MIW Jogja" type="article" key="Umroh Ramah Kantong | Umroh Murah| Umroh Jogja Murah|Umroh Paket Komplit|Umroh tour bersertifikat"/>
                        <svg
                            className="fill-amber-400 w-10 h-10 lg:absolute lg:right-10  lg:top-[20px]"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"><path
                                d="M335.5 4l288 160c15.4 8.6 21 28.1 12.4 43.5s-28.1 21-43.5 12.4L320 68.6 47.5 220c-15.4 8.6-34.9 3-43.5-12.4s-3-34.9 12.4-43.5L304.5 4c9.7-5.4 21.4-5.4 31.1 0zM320 160a40 40 0 1 1 0 80 40 40 0 1 1 0-80zM144 256a40 40 0 1 1 0 80 40 40 0 1 1 0-80zm312 40a40 40 0 1 1 80 0 40 40 0 1 1 -80 0zM226.9 491.4L200 441.5V480c0 17.7-14.3 32-32 32H120c-17.7 0-32-14.3-32-32V441.5L61.1 491.4c-6.3 11.7-20.8 16-32.5 9.8s-16-20.8-9.8-32.5l37.9-70.3c15.3-28.5 45.1-46.3 77.5-46.3h19.5c16.3 0 31.9 4.5 45.4 12.6l33.6-62.3c15.3-28.5 45.1-46.3 77.5-46.3h19.5c32.4 0 62.1 17.8 77.5 46.3l33.6 62.3c13.5-8.1 29.1-12.6 45.4-12.6h19.5c32.4 0 62.1 17.8 77.5 46.3l37.9 70.3c6.3 11.7 1.9 26.2-9.8 32.5s-26.2 1.9-32.5-9.8L552 441.5V480c0 17.7-14.3 32-32 32H472c-17.7 0-32-14.3-32-32V441.5l-26.9 49.9c-6.3 11.7-20.8 16-32.5 9.8s-16-20.8-9.8-32.5l36.3-67.5c-1.7-1.7-3.2-3.6-4.3-5.8L376 345.5V400c0 17.7-14.3 32-32 32H296c-17.7 0-32-14.3-32-32V345.5l-26.9 49.9c-1.2 2.2-2.6 4.1-4.3 5.8l36.3 67.5c6.3 11.7 1.9 26.2-9.8 32.5s-26.2 1.9-32.5-9.8z" /></svg>
                        <a href="#">
                            <span
                                className="mb-2 text-2xl font-semibold tracking-tight bg-gradient-to-r from-amber-400 via-amber-500 to-orange-500 bg-clip-text text-transparent ">Pembimbing
                                Bersertifikat</span>
                        </a>
                        <p
                            className="mb-3 font-normal text-gray-200 ">Insya Allah perjalanan jamaah aman nyaman dan benar karena  bimbingan oleh tenaga ahli yang  berpengalaman yang mempunyai sertifikat BNSP dalam Haji dan Umroh. Insya Allah MIW  adalah pilihan tepercaya untuk perjalanan ibadah kita </p>

                    </div>
                </div>

            </div>
        </div>
    )
}
