import logo from './logo.svg';
import { HelmetProvider } from 'react-helmet-async';
import { HF } from './components/hf';
import { Timeline } from './components/timeline';
import { MIWProud } from './components/MIWProud';
import { FAQ } from './container/FAQ';
import { Brocure } from './components/Brocure';
import { Perlengkapan } from './components/Perlengkapan';
import { Pricing } from './components/Pricing';
import { Footer } from './components/footer';
function App() {
  const helmetContext = {};
  return (
    <div className='bg-gradient-to-b from-neutral-950 to-black '>
      <HelmetProvider context={helmetContext}>
      <HF />
      <Brocure/>
      <Pricing/>
      <MIWProud/>
      <Timeline/>
      <Perlengkapan/>
      <FAQ/>
      <Footer/>
      </HelmetProvider>
    </div>
  );
}

export default App;
